module.exports = [{
      plugin: require('/Users/dcardosods/Dev/dcardosods.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/dcardosods/Dev/dcardosods.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-44597517-2","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/dcardosods/Dev/dcardosods.github.io/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Daniel Cardoso dS","short_name":"DanielCardoso","start_url":"/","background_color":"grey","theme_color":"grey","display":"minimal-ui","icon":"content/assets/dcds-icon.svg"},
    },{
      plugin: require('/Users/dcardosods/Dev/dcardosods.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/dcardosods/Dev/dcardosods.github.io/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    }]
